var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "380px",
        "custom-class": "fill-job-track",
        "show-close": false,
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "fill-job-track-wrapper" }, [
        _vm.dialogOpenStatus == 1
          ? _c("p", [
              _vm._v(
                "您选择的“" +
                  _vm._s(_vm.deleteStr) +
                  "”方向已被合并删除，请重新完善"
              ),
            ])
          : _vm._e(),
        _vm.dialogOpenStatus == 2
          ? _c("p", [_vm._v("您还未完善您所擅长的职位类型，请尽快进行完善")])
          : _vm._e(),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.hide } }, [_vm._v("关 闭")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: { click: _vm.jumpPersonalCenter },
            },
            [_vm._v("去完善")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="jobs" data-spm-page-id="P_job_search">
        <job-list
            ref="jobList"
            :jobList="jobList"
            :isMyFirm="isMyFirm"
            :params="params"
            :listLoading="loading"
            @updateOrderParams="updateOrderParams"
            @updateJobItem="updateJobItem"
            @refresh="getJobList"
        ></job-list>
        <!-- <jobs-right
            ref="jobsRight"
            :params="params"
            :isMyFirm="isMyFirm"
            :orderParams="orderParams"
            @search="filterJobOrder"
            @filterRecommendDegree="filterRecommendDegree"
        ></jobs-right> -->
        <fill-job-track-dialog></fill-job-track-dialog>
    </div>
</template>

<script>
import moment from 'moment';
import JobList from './layout/job-list.vue';
import JobsRight from './layout/jobs-right.vue';
import JobService from '#/js/service/jobService.js';
import FillJobTrackDialog from './component/fill-job-track-dialog.vue';
export default {
    name: 'JobListWrapper',
    components: {
        JobList,
        JobsRight,
        FillJobTrackDialog
    },
    data() {
        return {
            jobList: [],
            loading: false,
            params: {},
            orderParams: {
                customerIds: [],
                customerName: '',
                creatorIds: [],
                memberName: '',
                startDate: '',
                endDate: ''
            },
            jobSearch: null,
            isMyFirm: true
        };
    },
    mounted() {
        if(!this.isAdministrator && !this.isAgreementJob){
            shortTips("权限不足，请联系管理员")
            setTimeout(() => {
                location.href = "/Headhunting/#/";
            }, 2000)
        }

        let thisMonth = new Date().getMonth();
        let lastMonthDay = new Date().setMonth(thisMonth - 1);
        this.jobSearch = this.$refs.jobList.$refs.jobSearch;
        this.orderParams.startDate = moment(lastMonthDay).format('YYYY-MM-DD');
        this.orderParams.endDate = moment().format('YYYY-MM-DD');
        window.onbeforeunload = function(event) {
            sessionStorage.removeItem('jobSearchParams');
        };
    },
    destroyed() {
        sessionStorage.removeItem('jobSearchParams');
    },
    computed: {
        userFirmId() {
            return this.$store.state.user.userInfo.firmId;
        },
        isAdministrator() {
            return this.$store.state.user.userInfo.isAdministrator || false;
        },
        isAgreementJob() {
            return (this.$store.state.user.userInfo.privilegeCodeList || []).includes('AgreementJob');
        }
    },
    methods: {
        updateJobItem(job) {
            let jobIndex = this.jobList.findIndex(item => job.id === item.id);
            this.$set(this.jobList, jobIndex, job);
        },
        getJobList(params, slient, updateOrderType, searchLabelList) {
            console.log(`getJobList`, arguments)
            this.isMyFirm = !params.firmId || params.firmId === this.$store.state.user.userInfo.firmId;
            this.loading = true;
            if(params.firmId && this.params.firmId !== params.firmId) {
                let firmId = this.params.firmId ? this.params.firmId : this.$store.state.user.userInfo.firmId,
                    jobSearchParams = {},
                    jobSearch = this.$refs.jobList.$refs.jobSearch;

                if(sessionStorage.getItem('jobSearchParams')) {
                    jobSearchParams = JSON.parse(sessionStorage.getItem('jobSearchParams'));
                }

                // 切换公司tab 需要保留上一个公司tab的搜索条件
                jobSearchParams[firmId] = Object.assign({}, params, {
                    firmId: firmId
                });
                sessionStorage.setItem('jobSearchParams', JSON.stringify(jobSearchParams));

                // 授权公司无创建者 来源默认为内部职位 状态默认为招聘中
                if(!this.isMyFirm) {
                    Object.assign(params, {
                        creatorIds: [],
                        jobSource: 1,
                        jobStatus: 1
                    });
                }

                // 切换公司tab 首先判断是否有选中公司tab的搜索条件 如果有 则覆盖 如果没有 则重置
                if(jobSearchParams[params.firmId]) {
                    let searchParams = jobSearchParams[params.firmId];
                    Object.keys(searchParams).forEach(key => {
                        switch(key) {
                            case 'customerIds':
                                jobSearch.customerId = searchParams.customerIds[0];
                                break;
                            case 'creatorIds':
                                if(this.isMyFirm) {
                                    this.$nextTick(() => {
                                        jobSearch.$refs.teamSelect.handleClear();
                                        jobSearch.$refs.teamSelect.selectByIdList(searchParams.creatorIds);
                                        jobSearch.creators = jobSearch.$refs.teamSelect.selectedText;
                                    });
                                }
                                break;
                            case 'endDate':
                                jobSearch.createdTime = [searchParams.startDate, searchParams.endDate];
                                break;
                        }
                    });
                    Object.assign(jobSearch.jobSearchForm, searchParams);
                    Object.assign(params, searchParams);
                } else {
                    params = jobSearch.jobSearchForm = {
                        keyword: '',
                        jobName: '',
                        customerIds: [],
                        primaryCustomerIds: [],
                        creatorIds: [],
                        teamIndex: 0,
                        teamId: '',
                        startDate: '',
                        endDate: '',
                        jobLocation: 0,
                        minYearOfExperience: '',
                        minDegree: '',
                        emergencyDegree: '',
                        difficultDegree: '',
                        recommendDegree: '',
                        jobSource: 1,
                        jobStatus: 1,
                        orderBy: 0,
                        firmId: params.firmId,
                        categoryIds: []
                    };
                    params.start = 0;
                    jobSearch.customerId = '';
                    jobSearch.createdTime = [];
                    if(this.isMyFirm) {
                        jobSearch.creators = '';
                        this.$nextTick(() => {
                            jobSearch.$refs.teamSelect.handleClear();
                        })
                    }
                }
            }
            
            params.categoryIds = params.categoryIds?.map(it => it === 0 ? null: it);

            console.log('---------------------');
            console.log(searchLabelList);
            console.log(params);
            console.log(slient);
            // 埋点
            if(searchLabelList || (!searchLabelList && slient)) {
                console.log('执行了---------------');
                let searchTraceId = '';
                let eventId;
                let obj;

                let searchJobSource;
                if(params.jobSource == '2') {
                    searchJobSource = '发布到A2A';
                } else if(params.jobSource == '3') {
                    searchJobSource = '抢单A2A';
                } else if(params.jobSource == '4') {
                    searchJobSource = '抢单HR直招';
                } else {
                    searchJobSource = '内部职位';
                }

                let searchJobStatus;
                if(params.jobStatus == '2') {
                    searchJobStatus = '已暂停';
                } else if(params.jobStatus == '4') {
                    searchJobStatus = '已完成';
                } else if(params.jobStatus == '10') {
                    searchJobStatus = '已产生推荐';
                } else if(params.jobStatus == '11') {
                    searchJobStatus = '已安排面试';
                } else if(params.jobStatus == '12') {
                    searchJobStatus = '沉睡职位';
                } else {
                    searchJobStatus = '招聘中';
                }

                let searchSortBy;
                if(params.orderBy == '1') {
                    searchSortBy = '发布时间';
                } else if(params.orderBy == '2') {
                    searchSortBy = '佣金金额';
                } else if(params.orderBy == '3') {
                    searchSortBy = '佣金比例';
                } else {
                    searchSortBy = '更新时间';
                }

                let searchCustomerType;
                let searchCustomer = [];
                if(searchLabelList) { 
                    if(params.excludeCustomerIds?.length > 0) {
                        searchCustomerType = '屏蔽客户';
                        // searchCustomer = params.excludeCustomerIds;
                        searchCustomer = searchLabelList.excludeCustomer.value.split(',');
                    } else if(params.customerIds?.length > 0) {
                        searchCustomerType = '搜索客户';
                        // searchCustomer = params.customerIds;
                        searchCustomer = searchLabelList.customer.value.split(',');
                    } else {
                        searchCustomerType = '';
                        searchCustomer = [];
                    }
                }

                // 搜索查询埋点
                if(!slient) {
                    // 更新记录搜索查询的跟踪id（searchTraceId）在缓存中
                    searchTraceId = _generateUUID();
                    this.$store.dispatch('asyncUpdateSearchTraceId',searchTraceId)

                    eventId = 'E_job_search';
                    obj = {
                        element_spm: "P_job_search@A_job_query_search@L_job_query_search",
                        search_trace_id: searchTraceId,
                        // 关键词
                        search_keyword: params.keyword,
                        // 职位名称
                        search_job_title: params.jobName,
                        // 搜索客户类型
                        search_customer_type: searchCustomerType,
                        // 搜索客户
                        search_customer: searchCustomer,
                        // 创建者
                        search_creator: params.creatorIds?.length > 0 ? params.creatorIds : ['全部成员'],
                        // 创建时间范围
                        search_create_date_range: [params.startDate, params.endDate],
                        // 工作地点
                        search_job_location: searchLabelList?.locationIds.value ? searchLabelList.locationIds.value.split(',') : '全国',
                        // 紧急程度
                        search_job_emergency_degree: searchLabelList?.emergencyDegree.value ? searchLabelList.emergencyDegree.value : '不限',
                        // 职位难度
                        search_job_difficult_degree: searchLabelList?.difficultDegree.value ? searchLabelList.difficultDegree.value : '不限',
                        // 推荐状况
                        search_recommend_degree: searchLabelList?.recommendDegree.value ? searchLabelList.recommendDegree.value : '不限',
                        // 职位类别
                        search_job_category: searchLabelList?.categoryIds.value ? searchLabelList.categoryIds.value : '全部类别',
                        // 工作经验
                        search_year_experience: searchLabelList?.minYearOfExperience.value ? searchLabelList.minYearOfExperience.value : '不限',
                        // 学历
                        search_degree: searchLabelList?.minDegree.value ? searchLabelList.minDegree.value : '不限',
                        // 薪酬范围
                        search_salary_range: searchLabelList?.payment.value ? searchLabelList.payment.value : '不限',
                        // 职位来源
                        search_job_source: searchJobSource,
                        // 职位状态
                        search_job_status: searchJobStatus,
                        // 排序
                        search_sort_by: searchSortBy,

                        // 我擅长的职位
                        user_skilful_job_tag_ids: params.userSkilfulJobTagIds,
                        // 我抢单的
                        order_by_me: params.orderByMe,
                    }
                } else {  // 分页埋点
                    // 搜索查询条件不变更，点击分页埋点传的跟踪id（searchTraceId）不变，为上一个搜索查询的searchTraceId
                    if(this.$store.getters.getSearchTraceId) {
                        searchTraceId = this.$store.getters.getSearchTraceId;
                    } else {
                        searchTraceId = _generateUUID();
                    }

                    eventId = 'E_job_pagination_search';
                    obj = {
                        element_spm: "P_job_search@A_job_search_pagination@L_job_pagination",
                        search_trace_id: searchTraceId,
                        pagination_current: this.$refs.jobList.pager.current,
                        pagination_size: this.$refs.jobList.pager.take,
                    }
                }

                let json = {
                    eventId: eventId,
                    extendProperties: obj,
                }
                console.log(json);
                window.BazaCommonTrack.generateCustomEvent(json);
            }

            JobService.getDistributeAllJobList(params)
                .then(res => {
                    this.loading = false;
                    this.jobList = res.list;
                    this.$set(this.$refs.jobList.pager, 'total', res.total);
                    // this.setRecordStatus();
                    this.$nextTick(() => {
                        if(document.getElementsByClassName("list-container")[0]) {
                            document.getElementsByClassName("list-container")[0].scrollTop = 0;
                        }
                        if(document.getElementsByClassName("job-content-page")[0]) {
                            document.getElementsByClassName("job-content-page")[0].scrollTop = 0;
                        }
                        
                        // if(slient) {
                        //     document.querySelector('.jobs').scrollTop = document.querySelector('.job-content').offsetTop;
                        // } else if(slient !== null) {
                        //     document.querySelector('.jobs').scrollTop = document.querySelector('.job-search').offsetTop;
                        // }
                    });

                    // 获取左侧第一条数据
                    this.$refs.jobList.activeIdx = 0;
                    if(res.list[0]) {
                        this.$refs.jobList.getJobInfo(res.list[0]);
                    }
                }).catch(err => {
                    this.loading = false;
                    console.log(err);
                });
            this.params = params;
            // this.$nextTick(() => {
            //     this.$refs.jobsRight.getOrdersCountTop(updateOrderType);
            // });
        },
        setRecordStatus() {
            let jobIds = [];
            this.jobList.forEach(job => {
                if(job.id) {
                    jobIds.push(job.id);
                }
            });
            if (jobIds.length) {
                let params = {
                    jobIds: jobIds
                };
                JobService.getJobRecordStatus(params)
                    .then(res =>{
                        res.forEach(item => {
                            let jobVm = this.jobList.find(job => job.id === item.jobId);
                            jobVm.hasRecord = item.hasMedia;
                        });
                    }).catch(err => {
                        console.log(err);
                    });
            }
        },
        filterJobOrder(json) {
            let jobSearch = this.jobSearch;
            if(json.customerId) {
                jobSearch.$refs.customerSelect.handleCustomerChange({
                    customerName: json.customerName,
                    customerId: json.customerId
                });
            }
            if(json.creatorId) {
                jobSearch.$refs.teamSelect.handleClear();
                let selectedMember = jobSearch.$refs.teamSelect.memberList.find(member => member.userId === json.creatorId);
                if(selectedMember && selectedMember.userId) {
                    jobSearch.$refs.teamSelect.handleMemberSelect(selectedMember);
                    jobSearch.$refs.teamSelect.handleConfirm();
                } else {
                    jobSearch.creators = '';
                    jobSearch.jobSearchForm.creatorIds = [json.creatorId];
                    jobSearch.addParams(2);
                }
            }
        },
        updateOrderParams(params) {
            Object.assign(this.orderParams, params);
        },
        filterRecommendDegree(json) {
            this.jobSearch.jobSearchForm.recommendDegree = json.recommendDegree;
            this.jobSearch.changeCreatedTime(json.time);
        },

    }
};
</script>

<style lang="scss" scope>
    .jobs {
        // margin: 20px auto 30px;
        // width: 1200px;
        width: 100%;
        min-width: 1220px;
        height: 100%;
        overflow-y: auto;
        padding: 15px 15px 10px;
        // display: flex;
        // justify-content: space-between;
        &::-webkit-scrollbar {
            width: 10px;
            // background: #ebebeb;
            background: white;
        }
        &::-webkit-scrollbar-track, &::-webkit-scrollbar-thumb {
            border-width: 2px;
        }
        scrollbar-width: thin;
    }
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "jobs", attrs: { "data-spm-page-id": "P_job_search" } },
    [
      _c("job-list", {
        ref: "jobList",
        attrs: {
          jobList: _vm.jobList,
          isMyFirm: _vm.isMyFirm,
          params: _vm.params,
          listLoading: _vm.loading,
        },
        on: {
          updateOrderParams: _vm.updateOrderParams,
          updateJobItem: _vm.updateJobItem,
          refresh: _vm.getJobList,
        },
      }),
      _c("fill-job-track-dialog"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
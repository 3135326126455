<template>
  <el-dialog
    :visible="dialogVisible"
    width="818px"
    :show-close="true"
    v-loading="loading"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="handleClose"
    :before-close="handleClose"
    custom-class="protocol-job-dialog"
    class="protocol-job-dialog-wrapper"
    :append-to-body="true"
  >
    <span slot="title">协议职位<i class="title-tip">协议职位本团队的协议猎头可以查看</i></span>
    <div class="protocol-job">
      <div class="protocol-job-filter">
        <div class="filter-left">
          <el-input class="keyword-input" placeholder="职位名称关键词" v-model="keyword" @keyup.enter.native="handleSearch"></el-input>
          <!-- <el-select placeholder="选择成员" v-model="huntStatus">
                      <el-option :value="0">大佬</el-option>
                  </el-select> -->

          <div class="employee-select-wrap triangle-drop" :class="teamMemberPopoverVisible ? 'triangle-drop-up' : 'triangle-drop-down'">
            <el-popover
              popper-class="team-member-popover delivery-team-member-popover"
              placement="bottom"
              width="700"
              trigger="click"
              v-model="teamMemberPopoverVisible"
            >
              <team-member-select
                ref="teamSelect"
                :isAllMembers="true"
                :isShowDimission="false"
                :isHistoryData="true"
                :date="['2024-05-17', '2024-06-16']"
                :visible="teamMemberPopoverVisible"
                @item-select-cb="employeeSelect"
              ></team-member-select>
              <div slot="reference" class="employee-select-text">{{ selectedMembersText }}<i class="el-icon-arrow-up"></i></div>
            </el-popover>
          </div>

          <el-select placeholder="选择成员" v-model="sortType">
            <el-option :value="0" label="更新时间"></el-option>
            <el-option :value="1" label="发布时间"></el-option>
          </el-select>
        </div>
        <div class="filter-right">
          <el-button type="primary" @click="handleConfirmAdd">确认添加</el-button>
        </div>
      </div>
      <el-table border style="width: 100%" ref="standstillJobTable" :data="jobList" v-loading="jobTableLoading" @selection-change="handleSelectionChange">
        <!-- <el-table-column
                  type="selection"
                  width="28"
              ></el-table-column> -->
        <el-table-column prop="jobName" label="职位名称" width="250" :resizable="false" :show-overflow-tooltip="true">
          <template slot="header">
            <div class="table-slot">职位名称</div>
          </template>
          <template slot-scope="scope">
            <el-tooltip
              effect="light"
              :content="scope.row.jobName"
              placement="top"
              :open-delay="300"
              :disabled="tooltipDisabled"
              @mouseenter.native="handleShowTooltip($event)"
            >
              <span class="job-link" @click.prevent="handleJumpDetail(scope.row, 'job')">
                {{ scope.row.jobName }}
              </span>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column prop="city" label="城市" width="130" :resizable="false" :show-overflow-tooltip="true"></el-table-column>

        <el-table-column prop="time" label="发布时间" width="148" :resizable="false" :show-overflow-tooltip="true"></el-table-column>

        <el-table-column prop="status" label="状态" width="74" :resizable="false" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.status }}</template>
        </el-table-column>

        <el-table-column prop="status" label="归属人头像" width="94" :resizable="false" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.status }}</template>
        </el-table-column>

        <el-table-column label="操作" width="auto">
          <template slot-scope="scope">
            <el-checkbox @change="(val) => handleJobSelect(val, scope.$index)"></el-checkbox>
            <!-- <span class="delete-btn">删除</span> -->
          </template>
        </el-table-column>
        <template slot="empty">
          <div class="page-empty">
            <img src="~@src/assets/images/jobList/empty-bg@2x.png" />
            <p class="des">还没有协议职位，右上角添加</p>
          </div>
        </template>
      </el-table>
      <div class="work-table-footer">
        <el-pagination
          :current-page="pager.current"
          :page-size="pager.size"
          class="el-pagination-workTable"
          layout="total, sizes, prev, pager, next, slot"
          :total="pager.total"
          @current-change="handleCurrentChange"
        >
          <span class="pagination-text">
            <span>前往<el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页</span>
            <span @click="handlePagerJump">跳转</span>
          </span>
        </el-pagination>
      </div>
    </div>
  </el-dialog>
</template>
  
  <script>
import moment from 'moment'
import JobService from '#/js/service/jobService.js'
import myOrdersService from '@src/js/service/myOrdersService.js'
import TeamMemberSelect from '#/component/common/team-member-select.vue'
export default {
  components: {
    TeamMemberSelect,
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      keyword: '',
      huntStatus: 0,
      sortType: 0,
      teamMemberPopoverVisible: false,
      userIds: [],
      selectedMembersText: '全部成员',
      // jobList: [],
      jobList: [
        { jobName: 'web前端高级工程师', city: '北京、上海、深圳', time: '2024-12-23 12:30', status: 0 },
        { jobName: 'web前端高级工程师1', city: '北京、上海、深圳', time: '2024-12-23 12:30', status: 0 },
        { jobName: 'web前端高级工程师2', city: '北京、上海、深圳', time: '2024-12-23 12:30', status: 0 },
        { jobName: 'web前端高级工程师3', city: '北京、上海、深圳', time: '2024-12-23 12:30', status: 0 },
      ],
      jobTableLoading: false,
      multipleSelection: [],
      customerNameSearchGroupedItems: [],
      pagerJump: 0,
      pager: {
        current: 1,
        size: 8,
        total: 0,
      },
      tooltipDisabled: true,
      operations: [
        {
          enableTooltip: true,
          status: 1,
          color: 'primary',
          text: '不变',
          type: 'recruitingJob',
          toolTip: '选中后，将更新该职位的更新时间',
        },
        {
          enableTooltip: false,
          status: 2,
          color: 'orange',
          text: '暂停',
          type: 'pauseJob',
          toolTip: '',
        },
        {
          enableTooltip: false,
          status: 4,
          color: 'blue',
          text: '完成',
          type: 'finishJob',
          toolTip: '',
        },
      ],
      createdNewToOld: false,
      updatedNewToOld: true,
      jobData: [],
      jobFilterList: [],
      customerFilter: [],
      dialogId: '',
      isFromClick: false,
    }
  },
  computed: {
    doneNum() {
      let num = 0
      this.jobData.forEach((item) => {
        if (item.jobStatus > 0) {
          num++
        }
      })
      return num
    },
    isCfUser() {
      return this.$store.state.user.userInfo.isCFUser ? this.$store.state.user.userInfo.isCFUser : $('input[name="isCFUser"]').val()
    },
  },
  filters: {
    formatDate(string) {
      return moment(string).format('YYYY.MM.DD')
    },
  },
  mounted() {},
  methods: {
    show(dialogId, isFromClick) {
      this.dialogVisible = true
      this.dialogId = dialogId
      this.isFromClick = isFromClick

      this.$nextTick(() => {
        this.$refs.teamSelect && this.$refs.teamSelect.getHistoryTeamData(false)
      })
      // this.getJobData();
    },
    handleConfirmAdd() {
      //   this.$refs.jobAddDialog.show()
    },
    // 选择成员
    employeeSelect(userIds, text) {
      // this.json.userIds = userIds;
      // this.selectedMembersText = text;
      // this.teamMemberPopoverVisible = false;
      // this.page = 1;
      // this.getListData();

      this.selectedMembersText = text
      this.teamMemberPopoverVisible = false
    },
    handleSearch() {
      shortTips('关键词：' + this.keyword)
    },
    handleJobSelect(val, index) {
        console.log(val)
        console.log(index)
    },
    getJobData() {
      this.loading = true
      this.jobData = []
      JobService.getStandStillJob({ includeDetail: true })
        .then((res) => {
          let jobIds = []
          res.ossifiedJobs.forEach((item) => {
            item.jobStatus = 0
            jobIds.push(item.jobId)
          })
          res.ossifiedJobs.sort((item1, item2) => {
            return new Date(item2.updated).getTime() - new Date(item1.updated).getTime()
          })
          if (jobIds.length > 0) {
            if (window.lbdTrack) {
              lbdTrack.track('StandstillJobDialogJobList', {
                dialogId: this.dialogId,
                jobIds: jobIds,
              })
            } else {
              _tracker.track(
                'StandstillJobDialogJobList',
                JSON.stringify({
                  dialogId: this.dialogId,
                  jobIds: jobIds,
                })
              )
            }
          }
          this.jobData = res.ossifiedJobs
          this.getCustomerNameFilter()
          this.getJobFilterList()
        })
        .catch((err) => {
          console.log(err)
        })
      this.loading = false
    },
    getCustomerNameFilter() {
      let customerNameFilter = {},
        list = []
      this.jobData.forEach((item) => {
        if (customerNameFilter[item.customerName]) {
          customerNameFilter[item.customerName].count++
        } else {
          customerNameFilter[item.customerName] = {
            label: item.customerName,
            isSelect: this.customerFilter.indexOf(item.customerName) > -1 ? true : false,
            count: 1,
          }
        }
      })
      Object.keys(customerNameFilter).forEach((item) => {
        list.push(customerNameFilter[item])
      })
      list.sort((item1, item2) => {
        return item2.count - item1.count
      })
      this.customerNameSearchGroupedItems = list
    },
    getJobFilterList() {
      if (this.customerFilter.length > 0) {
        let list = [],
          jobData = this.jobData,
          filters = this.customerFilter
        this.jobData.forEach((item) => {
          if (filters.indexOf(item.customerName) > -1) {
            list.push(item)
          }
        })
        this.jobFilterList = list
      } else {
        this.jobFilterList = this.jobData
      }
      this.pager.total = this.jobFilterList.length
      this.pager.current = 1
      this.getJobList()
    },
    getJobList() {
      let start = 0
      start = (this.pager.current - 1) * this.pager.size
      this.jobList = this.jobFilterList.slice(start, start + this.pager.size)
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    multipleJobStatus(status, btnType, btnText) {
      let tipText = ''
      switch (status) {
        // case 1: tipText = `确定批量将这${this.multipleSelection.length}个职位设为继续招聘吗？`;break;
        case 1:
          tipText = `确定批量设置保持这${this.multipleSelection.length}个职位的状态不变吗？`
          break
        case 2:
          tipText = `确定批量暂停这${this.multipleSelection.length}个职位吗？`
          break
        case 4:
          tipText = `确定批量完成这${this.multipleSelection.length}个职位吗？`
          break
      }
      this.$confirm(tipText, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
      })
        .then(() => {
          let dataJson = [],
            jobIds = []
          this.multipleSelection.forEach((item) => {
            let itemObj = {
              jobId: item.jobId,
              jobStatus: status,
              isUpdateTime: status == 1 ? true : false,
            }
            dataJson.push(itemObj)
            jobIds.push(item.jobId)
          })
          if (window.lbdTrack) {
            lbdTrack.track('StandstillJobDialogOperation', {
              dialogId: this.dialogId,
              jobIds: jobIds,
              operationType: btnType,
              operationText: `批量操作${btnText}`,
            })
          } else {
            _tracker.track(
              'StandstillJobDialogOperation',
              JSON.stringify({
                dialogId: this.dialogId,
                jobIds: jobIds,
                operationType: btnType,
                operationText: `批量操作${btnText}`,
              })
            )
          }
          this.jobTableLoading = true
          myOrdersService
            .updateJobStatus(dataJson)
            .then((res) => {
              this.jobTableLoading = false
              shortTips('批量更新职位状态成功')
              this.multipleSelection.forEach((item) => {
                item.jobStatus = status
                item.updated = new Date().toISOString()
              })
              this.$refs.standstillJobTable.clearSelection()
              this.$emit('standstillJobUpdate', this.jobData.length - this.doneNum)
              this.checkDoneJob()
            })
            .catch((err) => {
              console.log(err)
              this.jobTableLoading = false
            })
        })
        .catch(() => {})
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getJobList()
    },
    handlePagerJump(pagerJump) {
      pagerJump = Number.parseInt(pagerJump)
      if (pagerJump > 0 && pagerJump <= Math.ceil(this.total / this.pager.take)) {
        this.handleCurrentChange(pagerJump)
      }
    },
    selectDataChange(label, result) {
      let list = []
      result.forEach((item) => {
        list.push(item.label)
      })
      this.customerFilter = list
      this.getJobFilterList()
    },
    handleShowTooltip(event) {
      let cell = event.target
      setTimeout(() => {
        this.tooltipDisabled = cell.clientWidth == cell.scrollWidth
      }, 300)
    },
    handleJumpDetail(item, type) {
      switch (type) {
        case 'job':
          // window.open(`/Headhunting/MyCompany.html#/Job/${item.jobId}`, '_blank');
          window.open(`/#/Job/${item.jobId}`, '_blank')
          break
        case 'customer':
          // window.open(`/Headhunting/MyCompany.html#/Customer/${item.customerId}`, '_blank');
          window.open(`/#/Customer/${item.customerId}`, '_blank')
          break
      }
    },
    handleJobStatus(operation, index) {
      let dataJson = []
      dataJson.push({
        jobId: this.jobList[index].jobId,
        jobStatus: operation.status,
        isUpdateTime: operation.status == 1 ? true : false,
      })
      if (window.lbdTrack) {
        lbdTrack.track('StandstillJobDialogOperation', {
          dialogId: this.dialogId,
          jobId: this.jobList[index].jobId,
          operationType: operation.type,
          operationText: operation.text,
        })
      } else {
        _tracker.track(
          'StandstillJobDialogOperation',
          JSON.stringify({
            dialogId: this.dialogId,
            jobId: this.jobList[index].jobId,
            operationType: operation.type,
            operationText: operation.text,
          })
        )
      }
      this.jobTableLoading = true
      myOrdersService
        .updateJobStatus(dataJson)
        .then((res) => {
          this.jobTableLoading = false
          shortTips('职位状态更新成功')
          this.jobList[index].jobStatus = operation.status
          this.jobList[index].updated = new Date().toISOString()
          this.$emit('standstillJobUpdate', this.jobData.length - this.doneNum)
          if (index == this.jobList.length - 1) {
            setTimeout(() => {
              this.checkDoneJob()
            }, 1000)
          } else {
            this.checkDoneJob()
          }
        })
        .catch((err) => {
          console.log(err)
          this.jobTableLoading = false
        })
    },
    hideDialog() {
      this.dialogVisible = false
      if (document.querySelector('input[name="isCFUser"]')) {
        document.querySelector('.protocol-job-dialog-wrapper').style.display = 'none'
        document.querySelector('.v-modal').parentElement.removeChild(document.querySelector('.v-modal'))
      }
      this.createdNewToOld = false
      this.updatedNewToOld = true
      this.customerFilter = []
    },
    sortTime(code) {
      switch (code) {
        case 0:
          this.createdNewToOld = !this.createdNewToOld
          this.jobFilterList.sort((item1, item2) => {
            let created1 = new Date(moment(item1.created).format('YYYY/MM/DD')).getTime(),
              created2 = new Date(moment(item2.created).format('YYYY/MM/DD')).getTime()
            if (this.createdNewToOld) {
              return Number(created2) - Number(created1)
            } else {
              return Number(created1) - Number(created2)
            }
          })
          break
        case 1:
          this.updatedNewToOld = !this.updatedNewToOld
          this.jobFilterList.sort((item1, item2) => {
            let updated1 = new Date(moment(item1.updated).format('YYYY/MM/DD')).getTime(),
              updated2 = new Date(moment(item2.updated).format('YYYY/MM/DD')).getTime()
            if (this.updatedNewToOld) {
              return Number(updated2) - Number(updated1)
            } else {
              return Number(updated1) - Number(updated2)
            }
          })
          break
      }
      this.pager.current = 1
      this.getJobList()
    },
    checkDoneJob() {
      let thisPageJobDone = false
      thisPageJobDone = this.jobList.every((item) => {
        return item.jobStatus > 0
      })
      if (thisPageJobDone) {
        if (this.doneNum == this.jobData.length) {
          shortTips('真棒，所有职位已经处理完毕')
        } else if (this.pager.current != Math.ceil(this.pager.total / this.pager.size)) {
          shortTips('正在跳转到下一页...')
          setTimeout(() => {
            this.pager.current++
            this.getJobList()
          }, 1000)
        }
      }
    },
    handleClose() {
      this.dialogVisible = false
      if (!this.isFromClick) {
        this.$emit('auto-show-dialog-close')
      }
    },
  },
}
</script>
  
  <style lang="scss" scope>
.protocol-job-dialog {
  position: relative;
  .el-dialog__header {
    font-size: 18px;
    color: #444;
    .title-tip {
      margin-left: 15px;
      font-size: 14px;
      color: #ff6564;
    }
  }

  .protocol-job {
    &-filter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 15px;
      margin-top: 6px;
      padding-bottom: 10px;

      .filter-left {
        display: flex;
        align-items: center;
        column-gap: 10px;

        .keyword-input {
          width: 262px;

          .el-input__inner {
            width: 100%;
            height: 32px;
            line-height: 32px;
          }
        }

        .el-select {
          width: 174px;
					
					.el-input__inner {
						line-height: 32px;
						height: 32px;
					}
        }

        .employee-select-wrap {
          position: relative;
          width: 188px;
          height: 32px;
          line-height: 32px;
          border: 1px solid #ddd;
          border-radius: 4px;
          cursor: pointer;

          .employee-select-text {
            width: 100%;
            padding: 0 15px;
          }

          .el-icon-arrow-up {
            position: absolute;
            right: 10px;
            line-height: 28px;
            color: #999;
            transition: all 0.3s;
            transform: rotateZ(180deg);
            pointer-events: none;
            z-index: 10;
          }

          &.triangle-drop-up {
            .el-icon-arrow-up {
              transform: rotateZ(0);
            }
          }
        }
      }

      .filter-right .el-button {
        width: 116px;
        height: 32px;
        line-height: 32px;
      }
    }

    .el-table {
			color: #333;

			thead {
				font-weight: 400;
				color: #333;
			}

      .job-link {
        color: $primary;
        cursor: pointer;
      }

      .delete-btn {
        font-size: 14px;
        color: #ff4b4b;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      th {
        padding: 7px 0;
        background-color: #f8f8f8;
      }

      td {
        padding: 7px 0;
      }
    }
  }

  .page-empty {
    width: 100%;
    padding-bottom: 30px;
    background: white;
    border-radius: 10px;
    text-align: center;
    > img {
      width: 158px;
      height: 178px;
      margin: 30px auto 0;
    }
    .des {
      margin: 12px auto 0;
      font-size: 14px;
      font-weight: 400;
      color: #333;
      line-height: 20px;
    }
  }

  .work-table-footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "protocol-job-dialog-wrapper",
      attrs: {
        visible: _vm.dialogVisible,
        width: "818px",
        "show-close": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.handleClose,
        "custom-class": "protocol-job-dialog",
        "append-to-body": true,
      },
      on: { closed: _vm.handleClose },
    },
    [
      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v("协议职位"),
        _c("i", { staticClass: "title-tip" }, [
          _vm._v("协议职位本团队的协议猎头可以查看"),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "protocol-job" },
        [
          _c("div", { staticClass: "protocol-job-filter" }, [
            _c(
              "div",
              { staticClass: "filter-left" },
              [
                _c("el-input", {
                  staticClass: "keyword-input",
                  attrs: { placeholder: "职位名称关键词" },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleSearch.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.keyword,
                    callback: function ($$v) {
                      _vm.keyword = $$v
                    },
                    expression: "keyword",
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass: "employee-select-wrap triangle-drop",
                    class: _vm.teamMemberPopoverVisible
                      ? "triangle-drop-up"
                      : "triangle-drop-down",
                  },
                  [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          "popper-class":
                            "team-member-popover delivery-team-member-popover",
                          placement: "bottom",
                          width: "700",
                          trigger: "click",
                        },
                        model: {
                          value: _vm.teamMemberPopoverVisible,
                          callback: function ($$v) {
                            _vm.teamMemberPopoverVisible = $$v
                          },
                          expression: "teamMemberPopoverVisible",
                        },
                      },
                      [
                        _c("team-member-select", {
                          ref: "teamSelect",
                          attrs: {
                            isAllMembers: true,
                            isShowDimission: false,
                            isHistoryData: true,
                            date: ["2024-05-17", "2024-06-16"],
                            visible: _vm.teamMemberPopoverVisible,
                          },
                          on: { "item-select-cb": _vm.employeeSelect },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "employee-select-text",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          },
                          [
                            _vm._v(_vm._s(_vm.selectedMembersText)),
                            _c("i", { staticClass: "el-icon-arrow-up" }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "选择成员" },
                    model: {
                      value: _vm.sortType,
                      callback: function ($$v) {
                        _vm.sortType = $$v
                      },
                      expression: "sortType",
                    },
                  },
                  [
                    _c("el-option", { attrs: { value: 0, label: "更新时间" } }),
                    _c("el-option", { attrs: { value: 1, label: "发布时间" } }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "filter-right" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.handleConfirmAdd },
                  },
                  [_vm._v("确认添加")]
                ),
              ],
              1
            ),
          ]),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.jobTableLoading,
                  expression: "jobTableLoading",
                },
              ],
              ref: "standstillJobTable",
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.jobList },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c(
                "el-table-column",
                {
                  attrs: {
                    prop: "jobName",
                    label: "职位名称",
                    width: "250",
                    resizable: false,
                    "show-overflow-tooltip": true,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                effect: "light",
                                content: scope.row.jobName,
                                placement: "top",
                                "open-delay": 300,
                                disabled: _vm.tooltipDisabled,
                              },
                              nativeOn: {
                                mouseenter: function ($event) {
                                  return _vm.handleShowTooltip($event)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "job-link",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.handleJumpDetail(
                                        scope.row,
                                        "job"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(scope.row.jobName) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("div", { staticClass: "table-slot" }, [
                      _vm._v("职位名称"),
                    ]),
                  ]),
                ],
                2
              ),
              _c("el-table-column", {
                attrs: {
                  prop: "city",
                  label: "城市",
                  width: "130",
                  resizable: false,
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "time",
                  label: "发布时间",
                  width: "148",
                  resizable: false,
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: "状态",
                  width: "74",
                  resizable: false,
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.status))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: "归属人头像",
                  width: "94",
                  resizable: false,
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.status))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "auto" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-checkbox", {
                          on: {
                            change: (val) =>
                              _vm.handleJobSelect(val, scope.$index),
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "page-empty" }, [
                  _c("img", {
                    attrs: {
                      src: require("@src/assets/images/jobList/empty-bg@2x.png"),
                    },
                  }),
                  _c("p", { staticClass: "des" }, [
                    _vm._v("还没有协议职位，右上角添加"),
                  ]),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "work-table-footer" },
            [
              _c(
                "el-pagination",
                {
                  staticClass: "el-pagination-workTable",
                  attrs: {
                    "current-page": _vm.pager.current,
                    "page-size": _vm.pager.size,
                    layout: "total, sizes, prev, pager, next, slot",
                    total: _vm.pager.total,
                  },
                  on: { "current-change": _vm.handleCurrentChange },
                },
                [
                  _c("span", { staticClass: "pagination-text" }, [
                    _c(
                      "span",
                      [
                        _vm._v("前往"),
                        _c("el-input", {
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handlePagerJump.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.pagerJump,
                            callback: function ($$v) {
                              _vm.pagerJump = $$v
                            },
                            expression: "pagerJump",
                          },
                        }),
                        _vm._v("页"),
                      ],
                      1
                    ),
                    _c("span", { on: { click: _vm.handlePagerJump } }, [
                      _vm._v("跳转"),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <el-dialog
        width="380px"
        custom-class="fill-job-track"
        :show-close="false"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false">
        <div class="fill-job-track-wrapper">
            <p v-if="dialogOpenStatus == 1">您选择的“{{deleteStr}}”方向已被合并删除，请重新完善</p>
            <p v-if="dialogOpenStatus == 2">您还未完善您所擅长的职位类型，请尽快进行完善</p>
        </div>
        <span class="dialog-footer" slot="footer">
            <el-button @click="hide">关 闭</el-button>
            <el-button type="primary" @click="jumpPersonalCenter">去完善</el-button>
        </span>
    </el-dialog>
</template>

<script>
import jobCategoryService from '#/js/service/jobCategoryService.js'
export default {
    name: 'fillJobOrderDialog',
    props: {
        params: Object
    },
    computed: {
    },
    data() {
        return {
            dialogVisible: false,
            dialogOpenStatus: 0,
            deleteStr: ''
        }
    },
    created() {
        let currentTime = +new Date();
        let lastShowDialogTime = localStorage.getItem('showFillJobTrackDialogTime');
        if(lastShowDialogTime && (currentTime - lastShowDialogTime < 24*60*60*1000) && (new Date().getDate() != new Date(lastShowDialogTime).getDate())) {
            return false
        }
        jobCategoryService.checkIfNeedShowDialog()
            .then(res =>{
                if(res.status != 0) {
                    this.dialogOpenStatus = res.status;
                    this.dialogVisible = true;
                    if(res.status == 1) {
                        this.deleteStr = res.historicalJobCategoryName.join('、');
                    }
                    localStorage.setItem('showFillJobTrackDialogTime', +new Date());
                }
            })
    },
    methods: {
        hide() {
            this.dialogOpenStatus = 0;
            this.deleteStr = '';
            this.dialogVisible = false;
        },
        jumpPersonalCenter() {
            // window.open('/Headhunting/MyCompany.html#/personalCenter');
            window.open('/#/personalCenter');
            jobCategoryService.deleteExpiredJobCategoryMapping();
            this.hide();
        }
    }
}
</script>

<style lang="scss" scope>
    
</style>

<style lang="scss">
.fill-job-track {
    &-wrapper {
        padding-top: 10px;
    }
    .el-dialog__header {
        display: none;
    }
    .dialog-footer .el-button--primary {
        width: auto;
        padding: 0 10px;
    }
}
</style>

<template>
<div class="job-search-wrap">
    <div class="job-search">
        <div class="job-top">
            <el-menu
                :default-active="jobSearchForm.firmId"
                class="firm-tab-menu"
                mode="horizontal"
                text-color="#999999"
                active-text-color="#38bc9d"
                v-if="firmTabList.length > 0">
                <el-menu-item
                    v-for="(firmTab, index) in firmTabList"
                    class="firm-tab-item"
                    :class="{'is-active': isMyFirm && userFirmId === firmTab.firmId}"
                    :title="firmTab.shortName + (userFirmId !== firmTab.firmId ? '(授权)' : '')"
                    :key="index"
                    :index="firmTab.firmId"
                    @click.native="changeFirmId(firmTab.firmId)">
                    {{firmTab.shortName}}{{userFirmId !== firmTab.firmId ? '(授权)' : ''}}
                </el-menu-item>
            </el-menu>
            <el-menu
                class="firm-tab-menu"
                mode="horizontal"
                text-color="#999999"
                active-text-color="#38bc9d"
                v-else>
                <el-menu-item
                    class="firm-tab-item is-active">
                    协议职位搜索
                </el-menu-item>
            </el-menu>

            <div class="job-option-btn" v-if="false">
                <span class="update-job-status-btn"
                    @click="showStandstillJobDialog(true)"
                    v-if="standstillJobNum && isMyFirm">
                    <span class="btn-icon">
                        <font-icon
                            href="#icon-piliangbianji"
                        ></font-icon>
                    </span>
                    <span class="txt">批量更新僵尸职位({{ standstillJobNum }})</span>
                </span>
                <span class="update-job-status-btn"
                    @click="goToUpdateJobStatus"
                    v-if="standstillJobNum === 0 && isMyFirm">
                    <span class="btn-icon">
                        <font-icon
                            href="#icon-piliangbianji"
                        ></font-icon>
                    </span>
                    <span class="txt">批量更新职位状态</span>
                </span>
                <span class="update-job-status-btn"
                    @click="showJobBindDeptDialog(true)"
                    v-if="unbindDeptJobList.length > 0 && isMyFirm">
                    <span class="btn-icon">
                        <font-icon
                            href="#icon-piliangbianji"
                        ></font-icon>
                    </span>
                    <span class="txt">批量绑定职位部门({{unbindDeptJobNum}})</span>
                </span>
                <span class="update-job-status-btn"
                    @click="showJobOrderAmountDialog"
                    v-if="isMyFirm">
                    <span class="btn-icon">
                        <font-icon
                            href="#icon-dingdanshuliangbianhua"
                        ></font-icon>
                    </span>
                    <span class="txt">订单数量变化</span>
                </span>
                <span class="update-job-status-btn"
                    @click="showJobRecommendAmountDialog"
                    v-if="isMyFirm">
                    <span class="btn-icon">
                        <font-icon
                            href="#icon-dingdantuijianshuliang"
                        ></font-icon>
                    </span>
                    <span class="txt">订单推荐数量</span>
                </span>
            </div>

            <protocol-job-dialog
                ref="protocolJobDialog"
            ></protocol-job-dialog>

            <standstill-job-dialog
                ref="standstillJobDialog"
                @standstillJobUpdate="standstillJobUpdate"
                @auto-show-dialog-close="checkJobBindDeptDialog"
            ></standstill-job-dialog>
            <job-bind-dept-dialog
                ref="jobBindDeptDialog"
                @unbindDeptJobUpdate="unbindDeptJobUpdate"
            ></job-bind-dept-dialog>
            <job-order-amount-dialog
                ref="jobOrderAmount"
                :params="orderParams"
            ></job-order-amount-dialog>
            <job-recommend-amount-dialog
                ref="jobRecommendAmount"
                :params="orderParams"
                @filterRecommendDegree="(json) => $emit('filterRecommendDegree', json)"
            ></job-recommend-amount-dialog> 
        </div>

        <el-form
            ref="jobSearchForm"
            class="job-search-form"
            label-position="left"
            @submit.native.prevent
            :model="jobSearchForm">
            <div class="search-comp">
                <div class="search-l">
                    <div class="comp-l">
                        <el-cascader
                            class="category-cascader"
                            v-model="jobSearchForm.categoryIds"
                            :options="categoryMap"
                            @change="handleCategoryChange"
                            placeholder="职位类别"
                            :props="{ 
                                value: 'id',
                                label: 'name',
                                children: 'subJobCategories',
                                emitPath: false,
                                multiple: true,
                                expandTrigger: 'hover',
                            }"
                            popper-class="category-popper"
                            collapse-tags
                            clearable
                            :show-all-levels="false"
                        >
                        </el-cascader>
                        <!-- checkStrictly: true, -->
                    </div>
                    <div class="comp-m">
                        <input 
                            class="keyword"
                            type="text"
                            v-model.trim="jobSearchForm.keyword"
                            @keyup.enter="(ev) => addParams(0)"
                            placeholder="输入职位或公司关键词，支持全文搜索"
                            />
                    </div>
                    <div class="comp-r">
                        <button class="search-btn" @click.stop="(ev) => addParams(0)">搜索</button>
                    </div>
                </div>
                <div class="search-r">
                    <div class="filter-btn" @click="handleToggleFilter">
                        <span class="filter-icon">
                            <font-icon
                                href="#icon-loudou-copy"
                            ></font-icon>
                        </span>
                        筛选
                    </div>
                </div>
            </div>


            <!-- <el-form-item>
                <el-input
                    v-model.trim="jobSearchForm.keyword"
                    :maxlength="50"
                    placeholder="输入职位或公司关键词，支持全文搜索"
                    class="search-input search-input-key"
                    @keyup.enter.native="(ev) => addParams(0)"
                ></el-input>
            </el-form-item> -->
            <el-row :gutter="20" class="row-wrap" v-show="isShowMore">
                <el-col :span="6" class="item-cell">
                    <span class="item-label">职位</span>
                    <el-input
                        class="item-input"
                        v-model="jobSearchForm.jobName"
                        :maxlength="50"
                        placeholder="搜索职位名称"
                        @keyup.enter.native="(ev) => addParams(0)"
                    ></el-input>
                </el-col>
                <el-col :span="6" class="item-cell">
                    <span class="item-label">客户</span>
                    <customer-select-shield
                        ref="customerSelect"
                        :firmId="jobSearchForm.firmId"
                        placeholder="搜索客户简称/全称"
                        @customerSelected="changeCustomer"
                        @customers-selected="changeCustomers"
                    ></customer-select-shield>
                </el-col>
                <el-col :span="6" class="item-cell" v-if="isMyFirm">
                    <span class="item-label">创建者</span>
                    <el-popover
                        popper-class="team-member-popover"
                        placement="bottom"
                        width="700"
                        trigger="click"
                        v-model="teamMemberPopoverVisible"
                    >
                        <team-member-select
                            ref="teamSelect"
                            :isAllMembers="true"
                            :isShowDimission="false"
                            :visible="teamMemberPopoverVisible"
                            :selected="jobSearchForm.creatorIds"
                            @item-select-cb="membersSelect"
                        ></team-member-select>
                        <el-input
                            slot="reference"
                            class="item-input"
                            :readonly="true"
                            v-model="creators"
                        ></el-input>
                        
                    </el-popover>
                </el-col>
                <el-col :span="6" class="item-cell">
                    <span class="item-label">创建时间</span>
                    <el-date-picker
                        class="item-date"
                        align="left"
                        size="small"
                        type="daterange"
                        format="yyyy-MM-dd"
                        range-separator="至"
                        v-model="createdTime"
                        @change="changeCreatedTime"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :picker-options="jobCreateTimeOption"
                        popper-class="job-create-time-range"
                    ></el-date-picker>
                </el-col>
            </el-row>

            <div class="filter-container" v-show="isShowMore">
                <div class="filter-row">
                    <div class="filter-label">工作地点</div>
                    <div class="filter-content">
                        <el-checkbox-group 
                            class="search-checkbox-group"
                            :min="1"
                            :max="5"
                            v-model="jobSearchForm.locationIds"
                            @change="handleLocationsChange" >
                            <el-checkbox
                                class="search-checkbox"
                                v-for="city in popularCities"
                                :key="city.cityId"
                                :label="city.cityId" >
                                {{city.name}}
                            </el-checkbox>
                            <template v-if="normalCity.length>0">
                                <el-checkbox
                                    class="search-checkbox"
                                    v-for="city in normalCity"
                                    :key="city.cityId"
                                    :label="city.cityId" >
                                    {{city.name}}
                                </el-checkbox>
                            </template>
                        </el-checkbox-group>
                        <span class="more-job-location-btn" @click="showMoreLocation">更多</span>
                    </div>
                </div>
                <div class="filter-row">
                    <div class="filter-label">推荐状况</div>
                    <div class="filter-content">
                        <el-radio-group
                            class="search-radio-group"
                            v-model="jobSearchForm.recommendDegree"
                            @change="(val) => addParams(0)">
                            <el-radio
                                class="search-radio"
                                v-for="recommend in recommendMap"
                                :key="recommend.value"
                                :label="recommend.value">
                                {{recommend.label}}
                            </el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div class="filter-row">
                    <div class="filter-label">其他筛选</div>
                    <div class="filter-content">

                        <el-select 
                            class="filter-select"
                            v-model="jobSearchForm.minYearOfExperience" 
                            placeholder="工作经验"
                            @change="(val) => addParams(0)">
                            <el-option
                            v-for="item in yearOfExperienceMap"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                  
                        <el-select 
                            class="filter-select"
                            v-model="jobSearchForm.minDegree" 
                            placeholder="学历要求"
                            @change="(val) => addParams(0)">
                            <el-option
                            v-for="item in degreeMap"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>

                        <el-select 
                            class="filter-select"
                            v-model="jobSearchForm.payment" 
                            placeholder="薪酬范围"
                            @change="(val) => addParams(0)">
                            <el-option
                            v-for="item in paymentMap"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>

            <div class="search-param-container" v-if="isShowSearchCondition">
                <div class="search-param-list">
                    您的搜索结果数：
                    <el-tag
                        closable
                        effect="plain"
                        class="search-param-item"
                        v-for="(search, index) in searchConditionList"
                        :key="index"
                        @close="removeLabel(search)"
                        v-show="search.value">
                        {{search.text}}: {{search.value}}
                    </el-tag>
                    <span class="job-total">
                        共 <span class="text-primary">{{jobNumber | currency('', 0)}}</span> 个职位
                    </span>
                    <span class="clear-search text-primary" @click="handleClearSearch">
                        <i class="el-icon-delete"></i>
                        清空搜索条件
                    </span>
                </div>
            </div>
        </el-form>
       
        <el-dialog
            ref="citySelectDialog"
            :visible.sync="isShowMoreCity"
            custom-class="city-select-dialog">
            <span
                class="city-select-dialog-close el-icon-close"
                @click="handleCitySelectCancel"
            ></span>
            <province-city
                ref="provinceCity"
                :grid-length="12"
                :multipeLimit="5"
                :enableMultipeSelected="true"
                :enableHeader="provinceCityConfig.enableHeader"
                :enableFooter="provinceCityConfig.enableFooter"
                @confirm="handleCitySelectConfirm"
                @cancel="handleCitySelectCancel"
            ></province-city>
        </el-dialog>
    </div>

    <div class="status-filter-bar">
        <div class="status-cell">
            <!-- <span class="select-item" v-if="isMyFirm">
                <span class="item-label">来源：</span>
                <el-select class="text-select" v-model="jobSearchForm.jobSource"
                    @change="(val) => handleJobDropdown(val, 'jobSource')"
                    placeholder="请选择">
                    <el-option
                    v-for="item in jobSourceMap"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </span> -->
            <!-- @command="(val) => handleJobDropdown(val, 'jobSource')"
                    @visible-change="(val) => handleDropdownVisible(val, 'jobSource')"> -->
            <!-- <span class="select-item" v-if="isMyFirm">
                <span class="item-label">状态：</span>
                <el-select class="text-select" v-model="jobSearchForm.jobStatus" 
                    @change="(val) => handleJobDropdown(val, 'jobStatus')"
                    placeholder="请选择">
                    <el-option
                        v-for="item in jobStatusMap"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>

            </span> -->
            <span class="select-item" v-if="isMyFirm">
                <span class="item-label">我擅长的职位：</span>
                <el-select class="text-select" 
                    popper-class="skilful-job-select-popper"
                    style="width: 170px"
                    multiple
                    collapse-tags
                    :multiple-limit="5"
                    @change="(val) =>addParams(0)"
                    v-model="jobSearchForm.userSkilfulJobTagIds" 
                    placeholder="请选择">
                    <el-option
                        v-for="item in myJobTags"
                        :key="'cat_tag_' + item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </span>
            <span class="select-item" v-if="isMyFirm">
                <el-checkbox v-model="jobSearchForm.orderByMe" @change="(val) =>addParams(0)">我抢单的</el-checkbox>
            </span>
        </div>

        <div class="status-cell">
            <span class="select-item">
                <span class="item-label">共 <span class="num">{{jobNumber | currency('', 0)}}</span> 个职位</span>
            </span>
            <span class="select-item">
                <span class="item-label">排序方式：</span>
                <el-select class="text-select" v-model="jobSearchForm.orderBy"
                    @change="(val) => handleJobDropdown(val, 'orderBy')"
                    placeholder="请选择">
                    <el-option
                        v-for="item in orderByMap"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </span>
        </div>

    </div>
    
</div>
</template>

<script>
import TeamMemberSelect from '#/component/common/team-member-select.vue';
import ProvinceCity from '#/component/common/province-city.vue';
import CustomerSelectShield from '#/component/common/customer-select-shield-refactor.vue';

import JobCategoryService from '#/js/service/jobCategoryService.js';
import moment from 'moment'
import JobService from '#/js/service/jobService.js';
import JobOrderAmountDialog from '../component/job-order-amount-dialog.vue';
import JobRecommendAmountDialog from '../component/job-recommend-amount-dialog.vue';
import ProtocolJobDialog from '../component/protocol-job-dialog.vue';
import StandstillJobDialog from '#/component/common/dialog/standstill-job-dialog.vue';
import JobBindDeptDialog from '#/component/common/dialog/job-bind-dept-dialog.vue';
import { 
    data as dataUrl, 
    personal_center as personalCenterUrl,
    } from '#/js/config/api.json';


const defaultSearchLabels = {
    keyword: {
        name: "keyword",
        title: "关键词",
        value: ""
    },
    jobName: {
        name: "jobName",
        title: "职位",
        value: ""
    },
    customer: {
        name: "customer",
        title: "客户",
        value: ""
    },
    excludeCustomer: {
        name: "excludeCustomer",
        title: "屏蔽客户",
        value: ""
    },
    creator: {
        name: "creator",
        title: "创建者",
        value: ""
    },
    createDate: {
        name: "createDate",
        title: "创建时间",
        value: ""
    },
    jobLocation: {
        name: "jobLocation",
        title: "工作地点",
        value: ""
    },
    locationIds: {
        name: "locationIds",
        title: "工作地点",
        value: ""
    },
    minYearOfExperience: {
        name: "minYearOfExperience",
        title: "工作经验",
        value: ""
    },
    minDegree: {
        name: "minDegree",
        title: "学历要求",
        value: ""
    },
    payment: {
        name: "payment",
        title: "薪酬范围",
        value: ""
    },
    emergencyDegree: {
        name: "emergencyDegree",
        title: "紧急程度",
        value: ""
    },
    difficultDegree: {
        name: "difficultDegree",
        title: "职位难度",
        value: ""
    },
    recommendDegree: {
        name: "recommendDegree",
        title: "推荐状况",
        value: ""
    },
    categoryIds: {
        name: "categoryIds",
        title: "职位类别",
        value: ""
    },
};

export default {
    props: {
        jobNumber: {
            type: Number,
            default: 0
        },
        orderParams: Object,
    },
    components: {
        TeamMemberSelect,
        ProvinceCity,
        CustomerSelectShield,

        JobOrderAmountDialog,
        JobRecommendAmountDialog,
        ProtocolJobDialog,
        StandstillJobDialog,
        JobBindDeptDialog
    },
    data() {
        return {
            standstillJobNum: 0,
            unbindDeptJobNum: 0,
            unbindDeptJobList: [],

            teamMemberPopoverVisible: false,
            jobSearchForm: {
                keyword: "",
                jobName: "",
                customerIds: [],
                primaryCustomerIds: [],
                creatorIds: [],
                teamIndex: 0,
                teamId: "",
                startDate: "",
                endDate: "",
                jobLocation: 0,
                locationIds: [0],
                minYearOfExperience: "",
                minDegree: "",
                payment: '', // 薪资范围
                emergencyDegree: "",
                difficultDegree: "",
                recommendDegree: "",
                jobSource: 1,
                jobStatus: 1,
                orderBy: 0,
                firmId: "",
                categoryIds: [],

                orderByMe: false,
                userSkilfulJobTagIds: [],
            },
            customerList: [],
            creators: "全部成员",
            createdTime: "",
            jobCreateTimeOption: {
                disabledDate(date) {
                    return date.getTime() > Date.now();
                },
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                        const end = moment().subtract(1, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(1, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近7天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(6, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近30天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(29, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '本月',
                    onClick(picker) {
                        const start = moment().add('month', 0).format('YYYY-MM') + '-01';
                        const end = moment(start).add('month', 1).add('days', -1).format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '上月',
                    onClick(picker) {
                        const start = moment().subtract(1, 'month').format('YYYY-MM') + '-01';
                        const end = moment(start).subtract(-1, 'month').add('days', -1).format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            popularCities: [],
            yearOfExperienceMap: [
                {
                    value: "",
                    label: "不限"
                }, 
                {
                    value: 1,
                    label: "1-3年"
                }, {
                    value: 3,
                    label: "3-5年"
                }, {
                    value: 5,
                    label: "5-10年"
                }, {
                    value: 10,
                    label: "10年以上"
                }, {
                    value: 0,
                    label: "不要求"
                }
            ],
            degreeMap: [
                {
                    value: "",
                    label: "不限"
                }, 
                // {
                //     value: 1,
                //     label: "高中及以下"
                // }, 
                {
                    value: 2,
                    label: "大专"
                }, {
                    value: 3,
                    label: "本科"
                }, {
                    value: 4,
                    label: "硕士"
                }, {
                    value: 5,
                    label: "MBA"
                }, {
                    value: 6,
                    label: "博士"
                }
            ],
            paymentMap: [
                // {
                //     value: "",
                //     label: "不限"
                // }, 
                {
                    value: 1,
                    label: "10K以下"
                }, {
                    value: 2,
                    label: "10K-30K"
                }, {
                    value: 3,
                    label: "30K-50K"
                }, {
                    value: 4,
                    label: "50K-80K"
                }, {
                    value: 5,
                    label: "80K-100K"
                }, {
                    value: 6,
                    label: "100K以上"
                },
            ],
            emergencyMap: [
                {
                    value: "",
                    label: "不限"
                }, {
                    value: 1,
                    label: "紧急"
                }, {
                    value: 0,
                    label: "不紧急"
                }
            ],
            difficultMap: [
                {
                    value: "",
                    label: "不限"
                }, {
                    value: 1,
                    label: "较难"
                }, {
                    value: 0,
                    label: "容易"
                }
            ],
            recommendMap: [
                {
                    value: "",
                    label: "不限"
                }, {
                    value: 0,
                    label: "推荐0人"
                }, {
                    value: 1,
                    label: "推荐1人"
                }, {
                    value: 2,
                    label: "推荐2人"
                }, {
                    value: 3,
                    label: "推荐3人及以上"
                }
            ],
            categoryMap: [],
            jobSourceDropdownVisible: false,
            jobSourceMap: [
                {
                    value: 1,
                    label: "内部职位"
                }, {
                    value: 2,
                    label: "发布到A2A"
                }, {
                    value: 3,
                    label: "抢单A2A"
                }, {
                    value: 4,
                    label: "抢单HR直招"
                }
            ],
            jobStatusDropdownVisible: false,
            jobStatusMap: [
                {
                    value: 1,
                    label: "招聘中"
                }, {
                    value: 2,
                    label: "已暂停"
                }, {
                    value: 4,
                    label: "已完成"
                }, {
                    value: 10,
                    label: "已产生推荐"
                }, {
                    value: 11,
                    label: "已安排面试"
                }, {
                    value: 12,
                    label: "沉睡职位"
                }
            ],
            orderByDropdownVisible: false,
            orderByMap: [
                {
                    value: 0,
                    label: "更新时间"
                }, {
                    value: 2,
                    label: "佣金金额"
                }, {
                    value: 1,
                    label: "发布时间"
                }, {
                    value: 3,
                    label: "佣金比例"
                }
            ],
            isShowMoreCity: false,
            provinceCityConfig: {
                enableHeader: true,
                enableFooter: true
            },
            selectedCity: {
                text: '全国',
                id: '',
                isSelect: true,
            },
            // normalCity: {
            //     cityId: "",
            //     name: ""
            // },
            normalCity: [],
            firmTabList: [],
            isShowMore: false,
            customerName: "",
            searchLabels: JSON.parse(JSON.stringify(defaultSearchLabels)),
            searchConditionList: [],

            profile: {},
        }
    },
    computed: {
        jobSourceText() {
            let jobSourceSelected = this.jobSourceMap.find(item => item.value === this.jobSearchForm.jobSource);
            return jobSourceSelected.label
        },
        jobStatusText() {
            let jobStatusSelected = this.jobStatusMap.find(item => item.value === this.jobSearchForm.jobStatus);
            return jobStatusSelected.label
        },
        orderByText() {
            let orderBySelected = this.orderByMap.find(item => item.value === this.jobSearchForm.orderBy);
            return orderBySelected.label
        },
        userFirmId() {
            return this.$store.state.user.userInfo.firmId;
        },
        isMyFirm() {
            return !this.jobSearchForm.firmId || this.userFirmId === this.jobSearchForm.firmId;
        },
        isShowSearchCondition() {
            if(this.searchConditionList && this.searchConditionList.length > 0) {
                let showResult = this.searchConditionList.some(item => {
                    return item.value;
                })
                return showResult;
            } else {
                return false;
            }
        },
        myJobTags() {
            return this.profile?.userProfileTags?.myJobTags || [];
        },
    },
    mounted() {
        this.getFirmTabList();
        this.getPopularCities();
        this.getCategoryMap();
        this.getProfile();

        // this.getStandstillJobNum();
        if(location.href.indexOf('isShowStandstill=true') > -1) {
            this.showStandstillJobDialog();
            location.href = '/#/jobList';
        }
        if(location.href.indexOf('isShowJobBindDept=true') > -1) {
            this.showJobBindDeptDialog();
            location.href = '/#/jobList';
        }
    },
    destroyed() {
    },
    methods: {
        handleCategoryChange(val) {
            this.addParams(0);
        },
        handleToggleFilter() {
            this.isShowMore = !this.isShowMore;
            console.log(`this.isShowMore:`, this.isShowMore)
        },
        getUpdateStatusJob() {
            JobService.getRecruitingJobList({
                orderBy: 0,
                jobSource: 1,
                jobStatus: 1,
                start: 0,
                take: 10,
                batchModifyJobStatus: false,
                _: new Date().getTime()
            }).then(res => {
                if(res.isShow) {
                    let total = res.list.length + res.threeMonthBeforeList.length;
                    if(total > 0) {
                        let htmlString = `
                            <p>新的一周开始了，你现在有${total}个职位正在招聘中</p>
                            <p>有职位已经停止招聘了吗？快随手<span style="color:#38bc9d;">更新职位状态</span>吧~</p>
                        `;
                        this.$confirm(htmlString, '更新职位状态', {
                            dangerouslyUseHTMLString: true,
                            customClass: "update-job-status-confirm-dialog",
                            showClose: false,
                            cancelButtonText: '稍后提醒我',
                            confirmButtonText: '去更新'
                        }).then(() => {
                            localStorage.setItem('jobListJobStatusDate', moment().format('YYYY-MM-DD'));
                            this.goToUpdateJobStatus();
                        }).catch(() => {})
                    }
                } else {
                    this.goToUpdateJobStatus();
                }
            }).catch(err => {
                console.log(err)
            })
        },
        showProtocolJobDialog() {
            this.$refs.protocolJobDialog.show();
        },
        // 批量更新僵尸职位
        showStandstillJobDialog(isFromClick) {
            let dialogId = "";
            if(isFromClick) {
                dialogId = 'StandstillJobDialogShowClick-' + new Date().getTime();
                _tracker.track('StandstillJobDialogShowClick');
            } else {
                dialogId = 'StandstillJobDialogAutoShow-' + new Date().getTime();
            }
            localStorage.setItem('standstillJobDate', moment().format('YYYYMMDD'));
            this.$refs.standstillJobDialog.show(dialogId, isFromClick);
        },
        // 批量更新职位状态
        goToUpdateJobStatus() {
            window.open("/Headhunting/#/myOrders/createdJob?status=1");
        },
        // 批量绑定职位部门
        showJobBindDeptDialog(isFromClick) {
            let dialogId = '';
            if(isFromClick) {
                dialogId = 'JobBindDeptDialogShowClick-' + new Date().getTime();
                _tracker.track('JobBindDeptDialogShowClick');
            } else {
                dialogId = 'JobBindDeptDialogAutoShow-' + new Date().getTime();
            }
            localStorage.setItem('bindJobDeptDate', moment().format('YYYYMMDD'));
            this.$refs.jobBindDeptDialog.show();
        },
        // 订单数量变化
        showJobOrderAmountDialog() {
            this.$refs.jobOrderAmount.show();
        },
        // 订单推荐数量
        showJobRecommendAmountDialog() {
            this.$refs.jobRecommendAmount.show();
        },
        getUnbindDeptJobList() {
            JobService.getUnbindDeptJobList({
                customerId: '',
                orderBy: 0
            }).then(res => {
                this.unbindDeptJobList = res.data || [];
                this.unbindDeptJobUpdate(this.unbindDeptJobList.length);
                this.checkJobBindDeptDialog();
            })
        },
        checkJobBindDeptDialog() {
            if(this.$refs.standstillJobDialog && this.$refs.standstillJobDialog.dialogVisible) {
                return false;
            }
            let total = this.unbindDeptJobList.length,
                today = moment().format('YYYYMMDD');
            if(total > 10) {
                if(!localStorage.getItem('bindJobDeptDate') || localStorage.getItem('bindJobDeptDate') != today) {
                    localStorage.setItem('bindJobDeptDate', moment().format('YYYYMMDD'));
                    _tracker.track('JobBindDeptDialogAutoShow');
                    this.showJobBindDeptDialog();
                }
            }
        },
        standstillJobUpdate(number) {
            this.standstillJobNum = number;
            this.$store.dispatch('setStandstillJobNum', this.standstillJobNum);
        },
        unbindDeptJobUpdate(number) {
            this.unbindDeptJobNum = number;
            this.$store.dispatch('setUnbindDeptJobNum', this.unbindDeptJobNum);
        },
        getStandstillJobNum() {
            JobService.getStandStillJob({
                includeDetail: false
            }).then(res => {
                this.standstillJobUpdate(res.total);
                let total = this.standstillJobNum,
                    today = moment().format('YYYYMMDD');
                if(total >= 8 || (total > 0 && new Date().getDay() == 1)) {
                    if(!localStorage.getItem('standstillJobDate') || localStorage.getItem('standstillJobDate') != today) {
                        localStorage.setItem('standstillJobDate', moment().format('YYYYMMDD'));
                        let fromText = '';
                        if(total > 0 && new Date().getDay() == 1) {
                            fromText = 'MondayAndHasUndisposedJob';
                        } else if(total >= 8) {
                            fromText = 'EightUndisposedJobExceeded';
                        }
                        _tracker.track('StandstillJobDialogAutoShow', JSON.stringify({
                            from: fromText
                        }));
                        this.showStandstillJobDialog();
                    }
                } else if(total === 0 && new Date().getDay() == 1) {
                    if(!localStorage.getItem('jobListJobStatusDate') || localStorage.getItem('jobListJobStatusDate') != today) {
                        localStorage.setItem('jobListJobStatusDate', moment().format('YYYYMMDD'));
                        this.getUpdateStatusJob();
                    }
                }

                this.getUnbindDeptJobList();
            }).catch(err => {
                this.getUnbindDeptJobList();
            });
        },

        getPopularCities() {
            let url = "/data/popularCities";
            _request({
                url: url,
                method: 'GET',
                data: {
                    _: new Date().getTime()
                }
            }).then(res => {
                if(res && res.length) {
                    res.unshift({
                        name: '全国',
                        cityId: 0
                    })
                    this.popularCities = res;
                } else {
                    this.popularCities = [{
                        name: '全国',
                        cityId: 0
                    }];
                }
            }).catch(err => {
                console.log(err);
            })
        },
        getCategoryMap(firmId) {
            // JobCategoryService.getCategoryList({
            //     firmId: firmId ? firmId : this.userFirmId,
            //     hasChildrenJobCategory: false,
            //     hasJobCount: false
            // })
            // const params = {
            //     hasChildrenJobCategory: true,
            //     hasJobCount: true,
            // };
            // JobCategoryService
            // .jobCategoriesTree(params)
            let _firmId = firmId || this.userFirmId;
            _request({
                url: dataUrl.job_category + `?firmId=${_firmId}`,
                method: 'GET',
                apiType: 'oldApi',
            })
            .then(r => {
                const res = r.subJobCategories;
                let list = [];
                if(res && res.length) {
                    // list = res.slice(0, res.length);
                    list = res.slice(1);
                } else {
                    list = [];
                }
                // list[0].id = '';
                // list.unshift({
                //     id: "",
                //     name: "全部类别"
                // })
                this.categoryMap = [
                    {
                        id: null,
                        name: '全部类别',
                        subJobCategories: list,
                    },
                ];
            }).catch(err => {
                console.log(err)
            })
        },
        addParams(updateOrderType) {
            let searchList = this.jobSearchForm,
                searchLabelList = this.searchLabels,
                selectedItem = {};

            Object.keys(searchList).forEach(item => {
                switch(item) {
                    case 'start':
                    case 'take':
                    case 'teamIndex':
                    case 'teamId':
                    case 'jobSource':
                    case 'jobStatus':
                    case 'orderBy':
                    case 'firmId':
                    case 'primaryCustomerIds':
                    case 'endDate':
                    case 'minSalary':
                    case 'maxSalary':
                    case 'orderByMe':
                    case 'userSkilfulJobTagIds':
                        break;
                    case 'keyword':
                    case 'jobName':
                        if(/^\s+$/.test(searchList[item])) {
                            searchList[item] = searchList[item].trim();
                        }
                        searchLabelList[item].value = searchList[item];
                        break;
                    case 'customerIds':
                        searchLabelList.customer.value = this.jobSearchForm.customerIds.length >0 ? this.customerName : '';
                        break;
                    case 'excludeCustomerIds':
                        searchLabelList.excludeCustomer.value = this.jobSearchForm.excludeCustomerIds.length >0 ? this.customerName : '';
                        break;
                    case 'creatorIds':
                        searchLabelList.creator.value = this.creators === '全部成员' ? '' : this.creators;
                        break;
                    case 'startDate':
                        searchLabelList.createDate.value = searchList.startDate ? searchList.startDate + '至' + searchList.endDate : "";
                        break;
                    case 'jobLocation':
                        if(searchList.jobLocation) {
                            let selectCity = this.popularCities.find(city => city.cityId === searchList.jobLocation);
                            if(selectCity) {
                                this.selectedCity = {
                                    text: selectCity.name,
                                    id: selectCity.cityId,
                                    isSelect: true
                                }
                            }
                        } else {
                            this.selectedCity = {
                                text: "全国",
                                id: 0,
                                isSelect: true
                            }
                        }
                        searchLabelList.jobLocation.value = this.selectedCity.text === '全国' ? '' : this.selectedCity.text;
                        break;
                    case 'locationIds':
                        
                        
                        break;
                    case 'minYearOfExperience':
                        selectedItem = this.yearOfExperienceMap.find(item => item.value === searchList.minYearOfExperience);
                        searchLabelList.minYearOfExperience.value = selectedItem && selectedItem.label !== '不限' ? selectedItem.label : "";
                        break;
                    case 'minDegree':
                        selectedItem = this.degreeMap.find(item => item.value === searchList.minDegree);
                        searchLabelList.minDegree.value = selectedItem && selectedItem.label !== '不限' ? selectedItem.label : "";
                        break;
                    case 'payment':
                        selectedItem = this.paymentMap.find(item => item.value === searchList.payment);
                        searchLabelList.payment.value = selectedItem && selectedItem.label !== '不限' ? selectedItem.label : "";

                        // 薪酬最大最小值转换处理
                        const paymentMatchMap = {
                            0: [null, null],
                            1: [null, 10000],
                            2: [10000, 30000],
                            3: [30000, 50000],
                            4: [50000, 80000],
                            5: [80000, 100000],
                            6: [100000, null],
                        };
                        [ this.jobSearchForm.minSalary, this.jobSearchForm.maxSalary] = paymentMatchMap[searchList.payment == ''? 0: searchList.payment];

                        break;
                    case 'emergencyDegree':
                        selectedItem = this.emergencyMap.find(item => item.value === searchList.emergencyDegree);
                        searchLabelList.emergencyDegree.value = selectedItem && selectedItem.label !== '不限' ? selectedItem.label : "";
                        break;
                    case 'difficultDegree':
                        selectedItem = this.difficultMap.find(item => item.value === searchList.difficultDegree);
                        searchLabelList.difficultDegree.value = selectedItem && selectedItem.label !== '不限' ? selectedItem.label : "";
                        break;
                    case 'recommendDegree':
                        selectedItem = this.recommendMap.find(item => item.value === searchList.recommendDegree);
                        searchLabelList.recommendDegree.value = selectedItem && selectedItem.label !== '不限' ? selectedItem.label : "";
                        break;
                    case 'categoryIds':
                        if(searchList.categoryIds && searchList.categoryIds?.length > 0) {
                            let selectItems = searchList.categoryIds.map(it => this.findNodeInTree(this.categoryMap, it)?.name);
                            let selectItemsTxt = selectItems.join(",");
                            // selectedItem = this.findNodeInTree(this.categoryMap, searchList.categoryIds)
                            searchLabelList.categoryIds.value = selectItemsTxt && selectItemsTxt !== '全部类别' ? selectItemsTxt : "";
                        }
                        break;
                    default:
                        console.log(item)
                        searchLabelList[item].value = searchList[item];
                }
            })
            this.$emit('updateParams', updateOrderType, searchLabelList)
            this.updateSearchLabel();
        },
        findNodeInTree(treeArr, id) {
            if(treeArr && treeArr.length>0) {
                for(let i=0; i< treeArr.length; i++) {
                    if(treeArr[i].id == id) return treeArr[i];
                    if(treeArr[i].subJobCategories && treeArr[i].subJobCategories.length>0) {
                        const res = this.findNodeInTree(treeArr[i].subJobCategories, id);
                        if(res) return res;
                    }
                }
            }
        },
        
        // isSearchOrShield 搜索还是屏蔽,0为屏蔽
        changeCustomer(customer, updateOrderType = 3, isSearchOrShield) {
            
            this.jobSearchForm.customerIds = [];
            this.jobSearchForm.primaryCustomerIds = [];
            this.jobSearchForm.excludeCustomerIds = [];
            let params = {
                customerName: "",
                customerIds: ""
            }
            if(customer.customerId) {
                if(isSearchOrShield == 0) {
                    // 屏蔽
                    this.jobSearchForm.customerIds.length = 0;
                    this.jobSearchForm.excludeCustomerIds = [customer.customerId];
                } else {
                    this.jobSearchForm.excludeCustomerIds.length = 0;
                    this.jobSearchForm.customerIds.push(customer.customerId);
                    this.jobSearchForm.primaryCustomerIds.push(customer.customerId);
                }
                params.customerName = customer.customerName;
                params.customerIds = customer.customerId;
            }
            this.customerName = customer.customerId ? customer.customerName : "";
            this.$emit('updateOrderParams', params);
            this.addParams(updateOrderType);
        },

        // 多选且最多限制为5个客户   isSearchOrShield 搜索还是屏蔽,0为屏蔽
        changeCustomers(customers, updateOrderType = 3, isSearchOrShield) {
            this.jobSearchForm.customerIds = [];
            this.jobSearchForm.primaryCustomerIds = [];
            this.jobSearchForm.excludeCustomerIds = [];
            
            const _ids = customers.map(it => it.customerId);
            if(isSearchOrShield == 0) {
                this.jobSearchForm.excludeCustomerIds = _ids;
            } else {
                this.jobSearchForm.customerIds = _ids;
                this.jobSearchForm.primaryCustomerIds = _ids;
            }
            this.customerName = customers.map(it => it.customerName).join(',');

            this.addParams(updateOrderType);
        },

        changeCreatedTime(time) {
            if(time) {
                this.createdTime = time;
                this.jobSearchForm.startDate = moment(time[0]).format('YYYY-MM-DD');
                this.jobSearchForm.endDate = moment(time[1]).format('YYYY-MM-DD');
                this.$emit('updateOrderParams', {
                    startDate: this.jobSearchForm.startDate,
                    endDate: this.jobSearchForm.endDate
                });
            } else {
                this.jobSearchForm.startDate = "";
                this.jobSearchForm.endDate = "";
            }
            this.addParams(3);
        },
        showMoreLocation() {
            this.isShowMoreCity = true;

            // 显示更多的时候把 流行城市同步到那边
            const _locationIds = this.jobSearchForm.locationIds.filter(it => it != 0);
            // let _selectcities = _locationIds.map(it => {
            //     const obj = this.popularCities.find(pIt => it == pIt.cityId);
            //     return {
            //         id: obj.cityId,
            //         selected: true,
            //         text: obj.name,
            //     };
            // });
            let _selectcities = [];
            this.popularCities.forEach(it => {
                if(_locationIds.includes(it.cityId)) {
                    _selectcities.push({
                        id: it.cityId,
                        selected: true,
                        text: it.name,
                    });
                }
            });
            this.normalCity.forEach(it => {
                if(_locationIds.includes(it.cityId)) {
                    _selectcities.push({
                        id: it.cityId,
                        selected: true,
                        text: it.name,
                    });
                }
            });

            if(_locationIds.length > 0) {
                this.$nextTick(() => {
                    this.$refs.provinceCity.selectedCity = _selectcities;
                });
            }
        },
        handleDropdownVisible(visible, type) {
            this[`${type}DropdownVisible`] = visible;
        },
        handleJobDropdown(command, type) {
            // this.jobSearchForm[type] = command;
            this.addParams(0)
        },
        membersSelect(value, text) {
            this.creators = text;
            this.jobSearchForm.creatorIds = value;
            this.teamMemberPopoverVisible = false;

            this.$emit('updateOrderParams', {
                memberName: this.creators,
                creatorIds: value
            });
            this.addParams();
        },
        handleCitySelectConfirm() {
            // if (this.$refs.provinceCity.selectedCity[0]) {
            //     this.selectedCity = this.$refs.provinceCity.selectedCity[0];
            //     this.jobSearchForm.jobLocation = Number(this.$refs.provinceCity.selectedCity[0].id);
            // } else {
            //     this.jobSearchForm.jobLocation = 0;
            //     this.selectedCity = {
            //         text: '全国',
            //         id: '',
            //         isSelect: true,
            //     };
            // }
            if (this.$refs.provinceCity.selectedCity[0]) {
                const selectCities = this.$refs.provinceCity.selectedCity;
                const _text = selectCities.map(it => it.text).join(',');

                this.jobSearchForm.locationIds = selectCities.map(it => +it.id);
                this.searchLabels.locationIds.value = _text === '全国' ? '' : _text;

                const _normalCitys = selectCities.filter(it => !this.popularCities.some(city => city.cityId == it.id));
                this.normalCity = _normalCitys.map(it => ({
                    name: it.text,
                    cityId: +it.id,
                }));
            } else {
                this.jobSearchForm.locationIds = [0];
                this.searchLabels.locationIds.value = '';
            }

            this.$refs.provinceCity.currentIndex = undefined;
            this.$refs.provinceCity.selectedProvince = '';
            // let isHotCity = this.popularCities.some(city => city.cityId == this.selectedCity.id);
            // if(!isHotCity) {
            //     this.normalCity = {
            //         name: this.selectedCity.text,
            //         cityId: Number(this.selectedCity.id)
            //     };
            // }
            this.isShowMoreCity = false;
            this.addParams(0);
        },
        handleCitySelectCancel() {
            if (this.$refs.provinceCity.selectedCity[0] && this.$refs.provinceCity.selectedCity[0].id !== this.selectedCity.id) {
                this.$refs.provinceCity.selectCity(this.selectedCity);
            }
            this.$refs.provinceCity.currentIndex = undefined;
            this.$refs.provinceCity.selectedProvince = '';
            this.isShowMoreCity = false;
        },
        getFirmTabList() {
            _request({
                url: "/JobAuthorizationFirms",
                method: "GET"
            }).then(res => {
                if(res && res.length) {
                    let userInfo = this.$store.state.user.userInfo;
                    res.unshift({
                        firmId: userInfo.firmId,
                        shortName: userInfo.firmShortName
                    });
                    this.firmTabList = res;
                } else {
                    this.firmTabList = [];
                }
            })
        },
        changeFirmId(firmId) {
            this.jobSearchForm.firmId = firmId;
            this.handleClearSearch();
            this.$nextTick(() => {
                this.$refs.customerSelect.getCustomerList();
                this.getCategoryMap(firmId)
            })
        },
        removeLabel(param) {
            let search = this.jobSearchForm;
            if(this.searchLabels.hasOwnProperty(param.title)) {
                this.searchLabels[param.title].value = "";
            }
            switch(param.title) {
                case 'customer':
                case 'excludeCustomer':
                    this.$refs.customerSelect.handleCustomerChange({
                        customerName: "",
                        customerId: ""
                    });
                    break;
                case 'creator':
                    this.creators = "全部成员";
                    this.$nextTick(()=> {
                        this.$refs.teamSelect.handleClear();
                    })
                    this.jobSearchForm.creatorIds = [];
                    this.addParams(3);
                    break;
                case 'createDate':
                    this.createdTime = null;
                    this.changeCreatedTime();
                    break;
                case 'jobLocation':
                    this.selectedCity = {
                        text: '全国',
                        id: '',
                        isSelect: true,
                    };
                    search.jobLocation = 0;
                    this.addParams(0);
                    break;
                case 'locationIds':
                    search.locationIds = [0];
                    if(this.$refs.provinceCity) {
                        this.$refs.provinceCity.selectedCity = [];
                    }
                    this.addParams(0);
                    break;
                case 'categoryIds':
                    search[param.title] = [];
                    this.addParams(0);
                    break;
                default:
                    search[param.title] = "";
                    this.addParams(0);
            }
        },
        updateSearchLabel() {
            let list = [];
            Object.values(this.searchLabels).forEach(label => {
                if(label.value) {
                    list.push({
                        title: label.name,
                        text: label.title,
                        value: label.value
                    })
                }
            });
            this.searchConditionList = list;
        },
        handleClearSearch() {
            this.customerName = "";
            this.creators = "全部成员";
            this.createdTime = null;
            this.selectedCity = {
                text: '全国',
                id: '',
                isSelect: true,
            };
            if(this.isMyFirm) {
                this.$nextTick(()=> {
                    console.log(this.$refs)
                    this.$refs.teamSelect.handleClear();
                });
            }
            // 清空客户
            if(this.jobSearchForm.customerIds.length > 0) {
                this.$refs.customerSelect.handleCustomerChange({
                    customerName: "",
                    customerId: ""
                });
            }
            if(this.$refs.provinceCity) {
                this.$refs.provinceCity.selectedCity = [];
            }

            let params = {
                keyword: "",
                jobName: "",
                customerIds: [],
                primaryCustomerIds: [],
                excludeCustomerIds: [],
                creatorIds: [],
                startDate: "",
                endDate: "",
                jobLocation: 0,
                locationIds: [0],
                minYearOfExperience: "",
                minDegree: "",
                emergencyDegree: "",
                difficultDegree: "",
                recommendDegree: "",
                categoryIds: [],
                payment: '',
            };
            Object.assign(this.jobSearchForm, params);
            this.searchLabels = JSON.parse(JSON.stringify(defaultSearchLabels));
            this.addParams(3);
        },
        handleLocationsChange(val) {
            const _idx = this.jobSearchForm.locationIds.findIndex(it => it == 0);
            if(_idx == 0) {
                this.jobSearchForm.locationIds.splice(_idx, 1);
            } else if(_idx > 0) {
                this.jobSearchForm.locationIds = [0];
            }

            const allCities = [...this.popularCities, ...this.normalCity];
            const selectCities = allCities.filter(city => this.jobSearchForm.locationIds.includes(city.cityId));

            const _text = selectCities.map(it => it.name).join(',');
            this.searchLabels.locationIds.value = _text === '全国' ? '' : _text;

            this.addParams(0);
        },
        getProfile() {
            _request({
                url: personalCenterUrl.my_profile,
                method: "GET"
            }).then(res => {
                this.profile = res;
                // res.accountBalance = this.$store.state.user.userInfo.accountBalance;
                // res.isBindingPinganAccount = this.$store.state.user.userInfo.isBindingPinganAccount;
                // res.pinganAccount = this.$store.state.user.userInfo.pinganAccount || "";
                // res.isCFUser = this.$store.state.user.userInfo.isCFUser;
                // res.isEmployer = this.$store.state.user.userInfo.isEmployer;
                // this.user = res;
                // this.introductionInput = this.user.userProfileTags ? this.user.userProfileTags.introduction : "";
                // this.sloganInput = this.user.profile.slogan;
                // this.user.profile.avatarUrl = this.$store.state.user.userInfo.avatarUrl;
            }).catch( err => {
                console.log(err);
            });
        },

    }
};
</script>

<style lang="scss" scope>
.category-popper{
    .el-cascader-panel li[aria-haspopup] .el-checkbox{
        display:none;
    }

    
    .el-checkbox {
        display: table;
        vertical-align: middle;
        width: 100%;
        height: 100%;
        position: absolute;
        box-sizing: border-box;
        margin-left: -25px;
        padding-left: 15px;
        // margin-top: 6px;
    }
    .el-cascader-menu .el-checkbox .el-checkbox__input {
        display: table-cell;
        vertical-align: middle;
    }
}
.job-search-wrap{

    .status-filter-bar{
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        margin: 12px 0 8px;
        background: white;
        border-radius: 5px;
        height: 46px;
        padding: 0 14px;
        .status-cell{
            .select-item{
                margin-right: 24px;
                &:last-child{
                    margin-right: 0;
                }
                .item-label{
                    font-size: 14px;
                    font-weight: 500;
                    color: #666666;
                    line-height: 20px;
                    .num{
                        color: #FF4B4B;
                    }
                }

                .text-select.el-select {
                    margin-right: 21px;
                    width: 126px;
                    height: 30px;
                    .el-input__inner {
                        height: 30px;
                        color: $primary;
                        font-size: 14px;
                        border: none;
                        background: transparent;
                    }

                    .el-input__prefix, .el-input__suffix {
                        height: 30px;
                    }

                    /* 下面设置右侧按钮居中 */
                    .el-input__suffix {
                        top: 0px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-wrap: nowrap;
                        flex-direction: row;
                        align-content: flex-start;
                    }
                    /* 输入框加上上下边是  + 2px */
                    .el-input__icon {
                        line-height: 32px;
                    }
                    .el-select__tags .el-tag.el-tag--info .el-select__tags-text{
                        max-width: 52px;
                        vertical-align: top;
                    }
                }

            }

        }
    }
}
.skilful-job-select-popper{
    .el-select-dropdown__item.selected{
        >span{
            max-width: 160px;
            display: inline-block;
            padding-right: 20px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
.job-search {
    position: relative;
    // margin-bottom: 20px;
    // background-color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    .job-top {
        height: 50px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        background-color: #ffffff;
        // border-radius: 8px 8px 0 0;
        .firm-tab-menu{ 
            flex: 1;
        }
        .firm-tab-menu.el-menu {
            display: flex;
            max-width: 900px;
            // background: blue;
            // border-radius: 8px 8px 0 0;
            .firm-tab-item.el-menu-item {
                // margin-left: 70px;
                margin-left: 30px;
                height: 50px;
                line-height: 50px;
                max-width: 200px;
                font-size: 16px;
                color: #999999;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                &.is-active {
                    color: $primary !important;
                    border-bottom: 3px solid #38BC9D !important;
                    // background-color: #f8f8f8;
                }
            }
        }
        .firm-tab-menu.el-menu--horizontal{
            border-bottom: none;
        }

        .job-option-btn {
            // position: absolute;
            // right: 0;
            // top: 13px;
            // z-index: 100;
            flex: 1;
            display: flex;
            justify-content: end;
            padding-right: 18px;
            .update-job-status-btn {
                height: 50px;
                line-height: 50px;
                display: inline-block;
                font-size: 14px;
                font-weight: 500;
                color: #333333;
                margin-left: 18px;
                cursor: pointer;
                .btn-icon{
                    color: #999;
                    margin-right: 5px;
                    vertical-align: 1px;
                    font-size: 18px;
                }
                &:after{
                    content: '';
                    display: inline-block;
                    background: #DCDCDC;
                    width: 1px;
                    height: 16px;
                    margin-left: 18px;
                    vertical-align: -4px;
                }
                &:hover{
                    color: $primary;
                    .btn-icon{
                        color: $primary
                    }
                }
                &:last-child::after{
                    display: none;
                }

                // margin-left: 32px;
                // height: 36px;
                // line-height: 36px;
                // background-color: #38BC9D ;
                // color: #fff;
                // font-size: 14px;
                // padding: 0 20px;
                // border-radius: 4px;
                // text-align: center;
                // cursor: pointer;
                // border: 1px solid #ddd;
                // &:hover {
                //     box-shadow: 0 2px 4px 0 #ccc;
                // }
                // &.job-order-dialog-btn {
                //     margin-right: 20px;
                // }
            }
        }
    }
    .text-primary {
        color: $primary;
    }
    
    // .el-menu--horizontal > .el-menu-item {
    //     border-bottom: 4px solid transparent;
    // }
    .job-search-form {
        position: relative;
        // padding: 20px 12px;
        padding: 20px 20px 12px;
        // padding-bottom: 2px;
        // background-color: #f8f8f8;
        background-color: #ffffff;
        border-top: 1px solid #EEEEEE;
        // border-radius: 8px;
        border-radius: 0 0 8px 8px;
        // margin-bottom: 20px;
        margin-right: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .el-form-item {
            width: 100%;
            margin-bottom: 20px;
            &.input-inner-label {
                width: 380px;
                .el-form-item__content {
                    position: relative;
                }
            }
            &.input-outer-label {
                // height: 26px;
                .el-form-item__label {
                    text-align: left;
                    margin-right: 5px;
                    margin-bottom: 0;
                    width: 80px;
                    color: #666;
                    padding-right: 0;
                    font-size: 14px;
                    line-height: 26px;
                    font-weight: bold;
                }
                .el-form-item__content {
                    line-height: 26px;
                    padding-left: 85px;
                    // height: 26px;
                }
            }
        }
        .form-input-label {
            height: 40px;
            width: 80px;
            text-align: center;
            display: inline-block;
            position: absolute;
            z-index: 1;
            top: 1px;
            left: 1px;
            white-space: nowrap;
            background: #fff;
            line-height: 40px;
            font-size: 14px;
            color: #333;
            border-radius: 4px;
            &::after {
                content: "";
                display: inline-block;
                width: 1px;
                height: 24px;
                background: #ddd;
                position: absolute;
                right: 0;
                top: 9px;
            }
        }
        // .customer-select-input, 
        .search-input {
            width: 100%;
            height: 42px;
            .el-input__inner {
                height: 42px;
                line-height: 42px;
                padding-right: 20px;
                padding-left: 92px;
            }
            &.search-input-key {
                width: 100%;
                .el-input__inner {
                    padding-left: 20px;
                }
            }
            &.el-date-editor {
                padding: 0;
                padding-left: 92px;
                line-height: 42px;
                .el-icon-date {
                    display: none;
                }
                .el-range-separator {
                    line-height: 40px;
                }
                .el-range__close-icon {
                    margin-left: 15px;
                    line-height: 40px;
                }
            }
        }
        .search-checkbox-group{
            display: inline-block;
            .search-checkbox{
            }
            .el-checkbox {
                line-height: 25px;
                padding: 0 10px;
                margin-right: 5px;
                margin-bottom: 5px;
                border-radius: 2px;
                &:hover {
                    background: #e9e9e9;
                }
                &.is-checked {
                    background: $primary;
                    &:hover {
                        background: $primary;
                    }
                    .el-checkbox__label {
                        color: #fff;
                    }
                }
                .el-checkbox__input {
                    display: none;
                }
                .el-checkbox__label {
                    padding-left: 0;
                }
            }
        }

        .el-radio-group.search-radio-group {
            // height: 26px;
            .el-radio {
                line-height: 25px;
                padding: 0 10px;
                margin-right: 5px;
                margin-bottom: 5px;
                border-radius: 2px;
                &:hover {
                    background: #e9e9e9;
                }
                &.is-checked {
                    background: $primary;
                    &:hover {
                        background: $primary;
                    }
                    .el-radio__label {
                        color: #fff;
                    }
                }
                .el-radio__input {
                    display: none;
                }
                .el-radio__label {
                    padding-left: 0;
                }
            }
        }
        .more-job-location-btn {
            display: inline-block;
            line-height: 26px;
            padding: 0 10px;
            margin-right: 5px;
            color: $primary;
            cursor: pointer;
            &:hover {
                background: #e9e9e9;
            }
        }
        .more-btn {
            position: absolute;
            bottom: 12px;
            left: 20px;
            .icon-font {
                font-size: 14px;
                color: $primary;
                cursor: pointer;
            }
            &:hover, &:focus {
                .icon-font {
                    color: #48CAAC;
                    text-decoration: underline;
                }
            }
            &:active {
                .icon-font {
                    color: #36B495;
                    text-decoration: underline;
                }
            }
        }


        .search-comp{
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            .search-l{
                flex: 1;
                display: flex;
                max-width: 74%;
                height: 46px;
                background: #38BC9D;
                border-radius: 10px;
                border: 2px solid #38BC9D;

                .comp-l{
                    width: 190px;
                    min-width: 190px;
                    max-width: 190px;
                    background: white;
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                    overflow: hidden;
                    .category-cascader{
                        .el-input__inner{
                            border: none;
                            height: 42px;
                        }
                        .el-tag{
                            align-items: baseline;
                            >span{
                                &:hover{
                                    color: $primary;
                                }
                            }
                        }
                    }
                }
                .comp-m{
                    flex: 1;
                    background: white;
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                    overflow: hidden;
                    .keyword{
                        width: 100%;
                        height: 42px;
                        line-height: 42px;
                        color: #666;
                        font-size: 16px;
                        border: none;
                        text-indent: 5px;
                        background: white;
                        &::placeholder{
                            color: #999;
                        }
                    }
                }
                .comp-r{
                    width: 100px;
                    min-width: 100px;
                    .search-btn{
                        text-align: center;
                        line-height: 42px;
                        font-size: 18px;
                        font-weight: 500;
                        color: #FFFFFF;
                        cursor: pointer;
                        width: 100%;
                    }
                }
            }
            .search-r{
                // rgba(56,188,157, 0.13)
                .filter-icon{
                    color: #38BC9D;
                }
                .filter-btn{
                    display: inline-block;
                    text-align: center;
                    width: 87px;
                    height: 34px;
                    background: rgba(56,188,157, 0.13);
                    border-radius: 3px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #38BC9D;
                    line-height: 32px;
                    cursor: pointer;
                }
            }
        }
        
        .row-wrap{
            width: 100%;
            margin-top: 16px;
            margin-bottom: 16px;
            .item-cell{
                display: flex;
                .item-label{
                    display: inline-block;
                    font-size: 14px;
                    font-weight: 500;
                    color: #666666;
                    line-height: 34px;
                    margin-right: 12px;
                    white-space: nowrap;
                    &+span, &+div{
                        flex: 1;
                    }
                }
                .item-input{
                    height: 34px;
                    .el-input__inner {
                        height: 34px;
                    }
                }
                .item-date{
                    .el-range-input{
                        background: white;
                    }
                    .el-range-separator{
                        width: 30px;
                    }
                }
            }
        }
        .filter-container{
            width: 100%;
            background: #F6F6F6;
            border-radius: 8px;
            padding: 18px 18px 8px;
            .filter-row{
                display: flex;
                margin-bottom: 12px;
                line-height: 26px;
                .filter-label{
                    font-size: 14px;
                    font-weight: 600;
                    color: #333333;
                    margin-right: 20px;
                }   
                .filter-content{
                    flex: 1;
                    .filter-select.el-select {
                        margin-right: 21px;
                        width: 184px;
                        height: 30px;
                        .el-input__inner {
                            height: 30px;
                        }

                        .el-input__prefix, .el-input__suffix {
                            height: 30px;
                        }

                        /* 下面设置右侧按钮居中 */
                        .el-input__suffix {
                            top: 0px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-wrap: nowrap;
                            flex-direction: row;
                            align-content: flex-start;
                        }
                        /* 输入框加上上下边是 32px + 2px =34px */
                        .el-input__icon {
                            line-height: 32px;
                        }
                    }


                }
            }
        }


        .search-param-container {
            width: 100%;
            // margin-bottom: 10px;
            background: #fff;
            .search-param-list {
                // background: #f6f6f6;
                padding: 8px 0 0;
                .search-param-item.el-tag {
                    max-width: 815px;
                    white-space: normal;
                    line-height: 30px;
                    height: auto;
                    font-size: 14px;
                    margin-right: 15px;
                    margin-bottom: 8px;
                }
                .job-total {
                    display: inline-block;
                    margin-bottom: 8px;
                }
                .clear-search {
                    display: inline-block;
                    margin-bottom: 8px;
                    margin-left: 20px;
                    cursor: pointer;
                }
            }
        }


    }
    .job-search-bottom {
        // width: 1100px;
        // width: 70%;
        width: calc(100% - 490px);
        min-width: 564px;
        height: 60px;
        line-height: 60px;
        background-color: #ffffff;
        border-radius: 8px 8px 0 0;
        padding: 0 20px;
        min-height: 32px;
        .job-search-select {
            display: inline-block;
            // line-height: 32px;
            line-height: 60px;
            .job-search-dropdown-title {
                color: #666;
            }
            .job-search-dropdown.el-dropdown {
                .el-dropdown-link {
                    // color: #b9b9b9;
                    color: #38BC9D;
                }
            }
            &.job-search-select-jobSource {
                margin-right: 28px;
            }
            &.job-search-select-orderBy {
                margin-left: 28px;
                float: right;
            }
        }
        .job-list-sum {
            float: right;
            // line-height: 32px;
            line-height: 60px;
        }
    }
    .team-select-box {
        width: 480px;
        height: 371px;
        margin-top: 5px;
        position: absolute;
        z-index: 10;
        background: #fff;
        box-shadow: 2px 2px 6px #999;
        .team-select.visible {
            .team-select-bd {
                margin-top: 5px;
            }
        }
        .button-list {
            margin-top: 333px;
            margin-left: 15px;
        } 
    }

    // .search-param-container {
    //     margin-bottom: 10px;
    //     background: #fff;
    //     .search-param-list {
    //         background: #f6f6f6;
    //         padding: 8px 20px 0;
    //         .search-param-item.el-tag {
    //             max-width: 815px;
    //             white-space: normal;
    //             line-height: 24px;
    //             height: auto;
    //             margin-right: 15px;
    //             margin-bottom: 8px;
    //         }
    //         .job-total {
    //             display: inline-block;
    //             margin-bottom: 8px;
    //         }
    //         .clear-search {
    //             display: inline-block;
    //             margin-bottom: 8px;
    //             margin-left: 20px;
    //             cursor: pointer;
    //         }
    //     }
    // }
}
</style>

<style lang="scss">
    .job-search-dropdown-menu.el-dropdown-menu {
        margin-top: 0;
        padding: 0;
        .popper__arrow {
            display: none;
        }
        .el-dropdown-menu__item.active {
            background-color: #ebf8f5;
            color: $primary;
        }
    }
    .city-select-dialog.el-dialog {
        .el-dialog__header {
            display: none;
        }
        .el-dialog__body {
            position: relative;
        }
        .city-select-dialog-close {
            position: absolute;
            right: 20px;
            top: 16px;
            cursor: pointer;
            font-weight: bold;
            font-size: 18px;
            color: #b9b9b9;
            &:hover {
                color: #cf0224;
            }
        }
    }
</style>
